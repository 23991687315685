.formBox {
  position: relative;
  max-width: 50vw;
  min-width: 50vw;
  max-height: 80vh;
  overflow: auto;
  padding: 40px 20px 0 0;
  transition: all 0.4s ease-in-out;
}

.broaden {
  max-width: 100%;
  min-width: 100%;
  padding-right: 0;
}

.show-json {
  display: flex;
  align-items: center;
  position: fixed;
  top: 90px;
  right: 60px;
  color: #444;
}

.icon {
  position: absolute;
  right: -40px;
  cursor: pointer;
  margin-left: 20px;
}

.icon:hover {
  color: #1976d2;
}

.arrow {
  font-size: 24px !important;
}

.jsonBox {
  position: relative;
  min-height: 80vh;
  width: 100%;
  transition: all 0.4s ease-in-out;
}

.hide {
  width: 0;
  transform: translate(30vw, 0);
  overflow: hidden;
}

.checkboxWrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
}

.jsonBox-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.jsonBox-title {
  line-height: 40px;
  font-weight: 700;
}

.textarea {
  width: 100%;
  height: calc(100% - 40px);
  padding: 5px 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  resize: none;
  color: #444;
}

.addBtn {
  margin-left: 20px !important;
}

.item {
  position: relative;
  background-color: #eee;
  margin: 10px 50px 0;
  padding: 20px;
  border-radius: 10px;
  overflow: hidden;
}

.item:hover .deleteBtn {
  transform: translateX(-50px);
}

.deleteBtn {
  position: absolute;
  right: 20px;
  top: calc(50% - 25px);
  width: 50px;
  height: 50px;
  transform: translateX(80px);
  text-align: center;
  line-height: 70px;
  border-radius: 25px;
  background-color: #1976d2;
  transition: all 0.3s ease-in-out;
}

.deleteBtn:hover {
  cursor: pointer;
  box-shadow: 0 0 10px #aaa;
}

.deleteIcon {
  font-size: 28px !important;
  color: #fff;
}

.chat_template_box {
  display: flex;
  align-items: start;
  gap: 10px;
}

.chat_template_test {
  width: 30%;
}

.chat_template_test_mainBox {
  height: 137px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow: scroll;
}

.chat_template_test_tip {
  font-size: 10px;
  margin: 4px 14px 0;
  color: rgba(0, 0, 0, 0.6);
}

.test_res_box {
  background-color: #eee;
  min-height: 55px;
  padding: 10px;
  margin-top: 5px;
  border-radius: 4px;
}

.css-19qh8xo-MuiInputBase-input-MuiOutlinedInput-input.Mui-disabled {
  -webkit-text-fill-color: #000 !important;
}
